.wrapper {
    position: relative;
    margin-bottom: 100px;
}

.container_1 {
    position: absolute;
    bottom: -10%;
    min-width: 320px;
    width: 40%;
    background: linear-gradient(45deg, #828283 10%, #F0F0F5 80%);
    z-index: 1;
    border-radius: 10px;    
}

.container_2 {
    position: relative;
    margin-right: 0px;
    margin-left: auto;
    min-width: 320px;
    width: 70%;
    min-height: 400px;
    border-radius: 10px;
}

.title__content {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.title__content > p {
    color: #FFF;
}

.dark_shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
}