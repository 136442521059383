* {
    font-family: "Roboto Condensed", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #f0f0f3;
    /* width: 100vw;
    height: 100vh; */
    /* padding-right: 15px; */
}

/* стили для эффекта неоморфизма */
/*.neumorphism-box {*/
/*    margin: 10px 10px 10px 10px;*/
/*    width: 400px;*/
/*    height: 400px;*/

/*    border-radius: 50px;*/
/*    background-color: #f0f0f3;*/
/*    box-shadow: 10px 10px 21px #d3d3d6,*/
/*                -10px -10px 40px #fff;*/
/*}*/